<template>
  <relatorio :headers="headersRel" />
</template>
<script>
  import Relatorio from './Relatorio.vue'
  import { sortCurrency } from '../../utils/sorts'

  export default {
    components: {
      Relatorio,
    },
    data () {
      return {
      }
    },
    computed: {
      headersRel () {
        return [
          { align: 'left', sortable: true, text: 'Unidade de Negócio', value: 'unidade_negocio', width: 'auto', floatingfilter: true, report: true },
          { align: 'right', sortable: true, text: 'Pedidos Faturados', value: 'pedidos_faturados', width: 'auto', report: true, styleReport: { halign: 'right' } },
          { align: 'right', sortable: true, text: 'Valor Total (R$)', value: 'valor_total', width: 'auto', sort: sortCurrency, report: true, styleReport: { halign: 'right' }, styleXLSX: { type: 'number' } },
          { align: 'right', sortable: true, text: 'Média Diária (R$)', value: 'media_periodo', width: 'auto', sort: sortCurrency, report: true, styleReport: { halign: 'right' }, styleXLSX: { type: 'number' } },
          { align: 'right', sortable: true, text: 'Ticket Médio por Pedido (R$)', value: 'ticket_medio_pedido', width: 'auto', sort: sortCurrency, report: true, styleReport: { halign: 'right' }, styleXLSX: { type: 'number' } },
          { align: 'right', sortable: true, text: 'Ticket Médio por Produtor (R$)', value: 'ticket_medio_produtores', width: 'auto', sort: sortCurrency, report: true, styleReport: { halign: 'right' }, styleXLSX: { type: 'number' } },
        ]
      },
    },
  }
</script>
